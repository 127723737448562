import CarTableForCars from './carTableForCars';

export default CarTableForCars;
export const query = graphql`
  query AllCarsx(
    $skip: Int!
    $limit: Int!
    $carType: String!
  ) {
    allCarData(
      sort: { fields: CoalescedProbDeath }
      filter: {
        CarTypeTeoalida: { eq: $carType }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedProbInjury
        CoalescedRscRating
        ProbDeathNOTEquippedWithAEB
        ProbDeathIfEquippedWithAEB
        VehicleSizeCategory
      }
    }

    groupings: allCarData(
      filter: { CarTypeTeoalida: { eq: $carType } }
    ) {
      group(field: CoalescedProbDeath) {
        fieldValue
        totalCount
      }
    }
  }
`;
